import { render, staticRenderFns } from "./updateTComboCategory.vue?vue&type=template&id=f5961f88&"
import script from "./updateTComboCategory.vue?vue&type=script&lang=js&"
export * from "./updateTComboCategory.vue?vue&type=script&lang=js&"
import style0 from "./updateTComboCategory.vue?vue&type=style&index=0&id=f5961f88&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Java\\healthy\\healthy-frame-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f5961f88')) {
      api.createRecord('f5961f88', component.options)
    } else {
      api.reload('f5961f88', component.options)
    }
    module.hot.accept("./updateTComboCategory.vue?vue&type=template&id=f5961f88&", function () {
      api.rerender('f5961f88', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/healthy/tComboCategory/updateTComboCategory.vue"
export default component.exports