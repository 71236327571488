var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tComboCategory",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tComboCategoryForm",
          attrs: {
            model: _vm.tComboCategoryForm,
            rules: _vm.tComboCategoryFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "序号", prop: "id" } },
            [
              _c("Input", {
                attrs: {
                  disabled: true,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入序号",
                },
                model: {
                  value: _vm.tComboCategoryForm.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.tComboCategoryForm, "id", $$v)
                  },
                  expression: "tComboCategoryForm.id",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "分类名称", prop: "categoryName" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入分类名称",
                },
                model: {
                  value: _vm.tComboCategoryForm.categoryName,
                  callback: function ($$v) {
                    _vm.$set(_vm.tComboCategoryForm, "categoryName", $$v)
                  },
                  expression: "tComboCategoryForm.categoryName",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "上级分类", prop: "pid" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.tComboCategoryForm.pid,
                    callback: function ($$v) {
                      _vm.$set(_vm.tComboCategoryForm, "pid", $$v)
                    },
                    expression: "tComboCategoryForm.pid",
                  },
                },
                _vm._l(_vm.tTComboCategoryPatentList, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.categoryName))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "检查类型", prop: "physicalType" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.tComboCategoryForm.physicalType,
                    callback: function ($$v) {
                      _vm.$set(_vm.tComboCategoryForm, "physicalType", $$v)
                    },
                    expression: "tComboCategoryForm.physicalType",
                  },
                },
                _vm._l(_vm.physicalTypeTypePriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "封面图片", prop: "imageName" } },
            [
              _c(
                "Upload",
                {
                  ref: "up",
                  attrs: {
                    action: _vm.deployByFileUrl,
                    headers: _vm.accessToken,
                    disabled: _vm.disabled,
                    "on-success": _vm.handleSuccess,
                    "on-error": _vm.handleError,
                    format: ["jpg", "png"],
                    "max-size": 5120,
                    "on-format-error": _vm.handleFormatError,
                    "on-exceeded-size": _vm.handleMaxSize,
                    "before-upload": _vm.handleBeforeUpload,
                    type: "drag",
                  },
                },
                [
                  _vm.fileName == null || _vm.fileName.trim().length == 0
                    ? _c(
                        "div",
                        { staticStyle: { padding: "20px 0" } },
                        [
                          _c("Icon", {
                            staticStyle: { color: "#3399ff" },
                            attrs: { type: "ios-cloud-upload", size: "52" },
                          }),
                          _c("p", [_vm._v("点击这里或将文件拖拽到这里上传")]),
                          _vm._v(" 仅支持jpg、png格式文件 "),
                        ],
                        1
                      )
                    : _c("div", { staticStyle: { padding: "20px 0" } }, [
                        _vm._v(" " + _vm._s(_vm.fileName) + " "),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }