<style lang="less">
	.tComboCategory{
		.ivu-modal {
			width: 660px !important;
		}
	}
</style>
<template>
	<Modal v-model="show" :title="editTitle" class="tComboCategory"  :mask-closable="false">
		<Form ref="tComboCategoryForm" :model="tComboCategoryForm" :rules="tComboCategoryFormRule" :label-width="100">
					<FormItem label="序号" prop="id">
						<Input v-bind:disabled="true"  type="text" :maxlength=50 v-model="tComboCategoryForm.id" placeholder="请输入序号"/>
					</FormItem>
					<FormItem label="分类名称" prop="categoryName">
						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tComboCategoryForm.categoryName" placeholder="请输入分类名称"/>
					</FormItem>
					<FormItem label="上级分类" prop="pid">
<!--						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tComboCategoryForm.physicalTypeId" placeholder="请输入检查类型"/>-->
            <Select  v-bind:disabled="disabled"  v-model="tComboCategoryForm.pid" placeholder="请选择" clearable >
              <Option v-for="(item, i) in tTComboCategoryPatentList" :key="item.id" :value="item.id">{{item.categoryName}}</Option>
            </Select>
					</FormItem>
					<FormItem label="检查类型" prop="physicalType">
						<Select  v-bind:disabled="disabled"  v-model="tComboCategoryForm.physicalType" placeholder="请选择" clearable >
							<Option v-for="(item, i) in physicalTypeTypePriority" :key="item.value" :value="item.value">{{item.title}}</Option>
						</Select>
					</FormItem>
					<FormItem label="封面图片" prop="imageName">
<!--						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tComboCategoryForm.imageUrl" placeholder="请输入封面图片"/>-->

            <Upload
                :action="deployByFileUrl"
                :headers="accessToken"
                :disabled="disabled"
                :on-success="handleSuccess"
                :on-error="handleError"
                :format="[ 'jpg','png']"
                :max-size="5120"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :before-upload="handleBeforeUpload"
                type="drag"
                ref="up"
            >
              <div style="padding: 20px 0" v-if="fileName == null||fileName.trim().length==0">
                <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                <p>点击这里或将文件拖拽到这里上传</p>
                仅支持jpg、png格式文件
              </div>
              <div style="padding: 20px 0" v-else>
                {{fileName}}
              </div>
            </Upload>
					</FormItem>
<!--					<FormItem label="删除标识(0-未删除，1-已删除)" prop="delFlag">-->
<!--						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tComboCategoryForm.delFlag" placeholder="请输入删除标识(0-未删除，1-已删除)"/>-->
<!--					</FormItem>-->
<!--					<FormItem label="创建人" prop="createId">-->
<!--						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tComboCategoryForm.createId" placeholder="请输入创建人"/>-->
<!--					</FormItem>-->
<!--					<FormItem label="创建时间" prop="createTime">-->
<!--						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tComboCategoryForm.createTime" placeholder="请输入创建时间"/>-->
<!--					</FormItem>-->
<!--					<FormItem label="修改人" prop="updateId">-->
<!--						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tComboCategoryForm.updateId" placeholder="请输入修改人"/>-->
<!--					</FormItem>-->
<!--					<FormItem label="修改时间" prop="updateTime">-->
<!--						<Input v-bind:disabled="disabled"  type="text" :maxlength=50 v-model="tComboCategoryForm.updateTime" placeholder="请输入修改时间"/>-->
<!--					</FormItem>-->
		</Form>
		<div slot="footer">
			<Button type="text" @click="show=false">取消</Button>
			<Button type="primary" :loading="loading" @click="handSubmit" v-if="!disabled">提交</Button>
		</div>
	</Modal>
</template>
<script>
	import {addTComboCategory,updateTComboCategory,getTComboCategory,getTComboCategoryParentList } from '@/api/healthy/tComboCategory'
	import { getDictDataByType } from '@/api/index';
	export default {
		name: "updateTComboCategory",
		props: {
			value: {
				type: Boolean,
				default: false
			},
			TComboCategoryId: {
				type: String
			},
			modalTitle: {
				type: String
			}
		},
		data() {
			return {
				show: this.value,
				editTitle: this.modalTitle,
				loading: true,
				disabled:false,
				physicalTypeTypePriority :[],
				tComboCategoryForm: {
					id:'',
					categoryName:'',
					physicalTypeId:'',
					physicalTypeType:'',
					imageUrl:'',
          imageName:'',
					delFlag:'',
					createId:'',
					createTime:'',
					updateId:'',
					updateTime:'',
				},
				tComboCategoryFormRule: this.getTComboCategoryFormRule(),
        deployByFileUrl:"",
        accessToken:{},
        file:null,
        fileName:"",
        tTComboCategoryPatentList:[]
			}
		},
		methods: {
			getPhysicalTypeTypePriority(){
				getDictDataByType('checkType').then(res => {
					if (res.success) {
						 this.physicalTypeTypePriority = res.data;
					}
				});
			},
      getTComboCategoryParent(){
        console.log(1)
        getTComboCategoryParentList().then(res=>{
          if(res.success){
            console.log(res.data)
            this.tTComboCategoryPatentList = res.data;
          }
        })
      },
      //文件上传成功时的钩子
      handleSuccess (res, file) {
      },
      //文件上传失败时的钩子
      handleError( error, file, fileList){},
      //文件格式验证失败时的钩子
      handleFormatError (file) {
        this.$Notice.warning({
          title: '文件格式限制提示',
          desc: '文件 ' + file.name + ' 的格式错误,仅支持docx、ftl格式文件.'
        });
      },
      //文件超出指定大小限制时的钩子
      handleMaxSize (file) {
        this.$Notice.warning({
          title: '文件大小限制提示',
          desc: '文件  ' + file.name + ' 太大了, 不能超过 2M.'
        });
      },
      //上传文件之前的钩子
      handleBeforeUpload (file) {
        this.file = file;
        this.fileName = file.name;
        this.$Message.success('选择成功');
        //不自动上传
        return false ;
      },
			handSubmit() {

        let formData = new FormData();
        if(this.file!=null&&this.fileName.trim().length>0){
          formData.append("file", this.file);
          this.tComboCategoryForm.imageName = this.fileName;
        }

				this.$refs['tComboCategoryForm'].validate((valid) => {
					this.loading = true;
					if (valid) {
						if(this.TComboCategoryId!=null&&this.TComboCategoryId.trim().length>0){
							this.tComboCategoryForm.id=this.TComboCategoryId;
              formData.append("submissionData", JSON.stringify( this.tComboCategoryForm));
							updateTComboCategory(formData).then(res => {
								if (res.success) {
									this.closeModal(false);
									this.$emit('handSearch');
									this.$Message.success('保存成功');
								}else{
									this.$Message.error(res.msg);
								}
							}).finally(() => {
								this.loading = false;
							});
						}else{
              formData.append("submissionData", JSON.stringify( this.tComboCategoryForm));
							addTComboCategory(formData).then(res => {
								if (res.success) {
									this.closeModal(false);
									this.$emit('handSearch');
									this.$Message.success('保存成功');
								}else{
									this.$Message.error(res.msg);
								}
							}).finally(() => {
								this.loading = false;
							});
						}
					} else {
							this.loading = false;
						this.$Message.error('表单验证不通过！');
					}
				});
			},
			closeModal(val) {
				this.$emit('input', val);
			},
			initForm(){
				this.tComboCategoryForm= {
					id:'',
					categoryName:'',
					physicalTypeId:'',
					physicalTypeType:'',
					imageUrl:'',
          imageName:'',
					delFlag:'',
					createId:'',
					createTime:'',
					updateId:'',
					updateTime:'',
          pid:''
				};

        this.file = null;
        this.fileName = null;
        this.accessToken = {
          accessToken: this.getStore("accessToken"),
        };

        this.getPhysicalTypeTypePriority()
        this.getTComboCategoryParent();

			},
			getTComboCategoryFormRule() {
				return {
					// id: [
					// 	{required: true, message: '序号不能为空！', trigger: 'blur'},
					// 	{type: 'string', max: 50, message: '数据的最大长度为50！', trigger: 'blur'}
					// ],
					categoryName: [
						{required: true, message: '分类名称不能为空！', trigger: 'blur'},
						{type: 'string', max: 50, message: '数据的最大长度为50！', trigger: 'blur'}
					],
					// physicalTypeId: [
					// 	{required: true, message: '检查类型不能为空！', trigger: 'blur'},
					// 	{type: 'string', max: 50, message: '数据的最大长度为50！', trigger: 'blur'}
					// ],
					physicalTypeType: [
						{required: true, message: '检查类型不能为空！', trigger: 'blur',pattern: /.+/ },
					],
					imageName: [
            {required: true, message: '封面内容不能为空！', trigger: 'blur', pattern: /.+/},
					],
					// delFlag: [
					// 	{required: true, message: '删除标识(0-未删除，1-已删除)不能为空！', trigger: 'blur'},
					// 	{type: 'string', max: 50, message: '数据的最大长度为50！', trigger: 'blur'}
					// ],
					// createId: [
					// 	{required: true, message: '创建人不能为空！', trigger: 'blur'},
					// 	{type: 'string', max: 50, message: '数据的最大长度为50！', trigger: 'blur'}
					// ],
					// createTime: [
					// 	{required: true, message: '创建时间不能为空！', trigger: 'blur'},
					// 	{type: 'string', max: 50, message: '数据的最大长度为50！', trigger: 'blur'}
					// ],
					// updateId: [
					// 	{required: true, message: '修改人不能为空！', trigger: 'blur'},
					// 	{type: 'string', max: 50, message: '数据的最大长度为50！', trigger: 'blur'}
					// ],
					// updateTime: [
					// 	{required: true, message: '修改时间不能为空！', trigger: 'blur'},
					// 	{type: 'string', max: 50, message: '数据的最大长度为50！', trigger: 'blur'}
					// ],
				}
			}
		},
		watch: {
			value(val) {
				this.show = val;
			},
			show(val) {
				this.initForm();
				this.loading=false;
				this.editTitle = this.modalTitle;
				if(this.editTitle == "查看"){
					this.disabled = true;
				}
				else{
					this.disabled = false;
				}
				if(val) {
					this.$refs['tComboCategoryForm'].resetFields();
					this.$refs['tComboCategoryForm'].id = null;
					if(this.TComboCategoryId!=null&&this.TComboCategoryId.trim().length>0){
						getTComboCategory({id: this.TComboCategoryId}).then(res => {
								if (res.success) {
									 this.tComboCategoryForm = res.data;
                    this.fileName = res.data.imageName;
							} else {
								this.$Message.error(res.msg);
							}
						});
					}
				} else {
					this.closeModal(val);
				}
			}
		}
	}
</script>
